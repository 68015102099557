<div class="page-ttl-wrap">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h1>Payment Method</h1>
        <ul>
          <li><a href="/">Home</a></li>
          <li><a href="/cart">Cart</a></li>
          <li><a href="/checkout">Customer Information</a></li>
          <li>Payment Method</li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="payment-pg-wrap">
  <div class="container" [formGroup]="form">
    <div class="row">
      <div class="col-md-8 col-lg-9">
        <div class="row">

          <div class="col-md-12 mb-3">
            <div class="card">
              <div class="card-body">
                <h5>Select Payment Method</h5>
                <hr>
                <!-- <div class="custom-control custom-radio mb-2">
                  <input type="radio" id="customRadio1" name="customRadio" class="custom-control-input">
                  <label class="custom-control-label" for="customRadio1">Pay Via Online</label>
                </div>
                <div class="custom-control custom-radio">
                  <input type="radio" id="customRadio2" name="customRadio" class="custom-control-input">
                  <label class="custom-control-label" for="customRadio2">Cash On Delivery</label>
                </div> -->
                <mat-radio-group aria-label="Select an option" [formControl]="form.controls['payment_method']">
                  <div class="custom-control custom-radio mb-2">
                    <mat-radio-button value="1" disabled= boolean>Pay Via Online</mat-radio-button>
                  </div>
                  <div class="custom-control custom-radio mb-2">
                    <mat-radio-button value="2" [checked]=true>Cash On Delivery</mat-radio-button>
                  </div>
                </mat-radio-group>

                <mat-radio-group aria-label="Select an option" class="custom-radio-button"
                [formControl]="form.controls['cod_type_id']">
                    <mat-radio-button  value="1" [checked]=true>Pickup from Store</mat-radio-button>
                    <mat-radio-button class="radio-ml-15" value="2">Home Delivery (Charges Applicable)</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
          </div>

          <div class="col-md-12 mb-3 d-md-none cart-summry">
            <div class="card">
              <div class="card-body">
                <h5>Summary ({{cartService.cart.length}} Item(s))</h5><hr>
                <dl class="dlist-align">
                  <dt>Sub Total:</dt>
                  <dd class="text-right mb-0"><i class="fa fa-rupee-sign"></i>&nbsp;{{cartService.cartSubTotal}}</dd>
                </dl>
                <dl class="dlist-align">
                  <dt>Tax:</dt>
                  <dd class="text-right mb-0"><i class="fa fa-rupee-sign"></i>&nbsp;{{cartService.cartTaxTotal}}</dd>
                </dl>
                <dl class="dlist-align" *ngIf="cartService.roundOff != 0">
                  <dt>Round Off:</dt>
                  <dd class="text-right mb-0"><i class="fa fa-rupee-sign"></i>&nbsp;{{cartService.roundOff}}</dd>
                </dl>
                <hr>
                <dl class="dlist-align">
                  <dt>Total:</dt>
                  <dd class="text-right mb-0"><i class="fa fa-rupee-sign"></i>&nbsp;{{cartService.cartTotal}}</dd>
                </dl>
              </div>
            </div>
          </div>

          <div class="col-md-12 mb-3">
            <div class="card">
              <div class="card-body">
                <h5>Shipping Address <a routerLink="/checkout"><small>Edit</small></a></h5>
                <hr>
                <address><b>{{name}}</b><br> {{address}}, {{address_2}}
                  <br> {{location}}, {{pincode}}<br>
                  Email: {{email}} <br>
                  Phone: {{phone}}
                </address>
              </div>
            </div>

            <div class="mt-10">
              # Delivery in Kozhikode Corporation limit only & Charges will be applicable on Home delivery
            </div>

          </div>  
        </div>
      </div>
      <div class="col-md-4 col-lg-3 cart-summry d-none d-md-block" [hidden]="!cartService.cart.length">
        <div class="card">
          <app-sidebar-widget [is_disable_checkout]=is_disable_checkout></app-sidebar-widget>
        </div>
      </div>

    </div>
    <div class="row mt-3 mt-md-0">
      <div class="col-md-3 text-left red-clr">{{(!form.valid)?'* Please select payment method':''}}</div>
      <div class="col-md-6 text-right">
        <a routerLink="/checkout" class="btn btn-outline-primary mr-3"><i class="fa fa-chevron-left"></i>&nbsp;Return to
          Customer Info</a>
        <button (click)="paymentDetails()" class="btn btn-primary"
          [disabled]="!form.valid || is_disable_checkout || is_disbale_checkout_save">Submit&nbsp;<i class="fa fa-chevron-right"></i></button>
        <!-- routerLink="/thank-you" -->
      </div>
    </div>
    <div *ngIf="is_disable_checkout" class="col-md-12 disabled-text">Cart contain out of stock items. Please clear it
      before doing checkout.</div>
  </div>
</div>
<ngx-spinner></ngx-spinner>