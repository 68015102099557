import { Component, OnInit } from '@angular/core';
import { CartService } from '../service/cart.service';
import { Router } from '@angular/router';
import { TitleService } from '../service/title.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.css']
})
export class ThankYouComponent implements OnInit {
  public is_disable_checkout: boolean = false;
  public name;
  public email
  public phone
  public location
  public address
  public address_2
  public pincode
  public payment_method
  public order_id
  public messagecheck = environment.store.message
  public cart = { 'cart': [] };
  public codType

  constructor(public cartService: CartService, private router: Router, private title: TitleService) { }

  async ngOnInit() {

    this.title.setTitle('Thank you')
    //For rightbar widget
    await this.cartService.updateCartTotal()
    //End rightbar widget

    //check cart items are valid or not
    //check cart is not empty
    let check_cart_items = (JSON.parse(localStorage.getItem('cart'))) ? JSON.parse(localStorage.getItem('cart')) : [];

    //check shipping details
    let shipping_details = (JSON.parse(localStorage.getItem('shipping_details'))) ? JSON.parse(localStorage.getItem('shipping_details')) : "";
    let isData: boolean = true;
    // if (shipping_details) {
    //   if (shipping_details.name === '' || shipping_details.phone === '' || shipping_details.email === '' || shipping_details.location === '' || shipping_details.address === '' || shipping_details.address_2 === '' || shipping_details.pincode === '') {
    //     isData = false
    //   }
    // }

    if (shipping_details) {
      if (shipping_details.name === '' || shipping_details.phone === '' || shipping_details.location === '' || shipping_details.address === '' || shipping_details.pincode === '') {
        isData = false
      }
    }

    //check payment details
    let payment_details = (JSON.parse(localStorage.getItem('payment_details'))) ? JSON.parse(localStorage.getItem('payment_details')) : "";
    let isPaymentData: boolean = true;
    if (payment_details) {
      if (payment_details.payment_method === '') {
        isPaymentData = false
      }
    }

    //check items are in stock
    // let res = (this.cartService.cart).filter(x => x.is_stock !== true);
    let res = (this.cartService.cart).filter(x => x.is_stock === false);
    this.is_disable_checkout = (res.length > 0) ? true : false

    if (check_cart_items.length === 0 || !isData || !isPaymentData || this.is_disable_checkout) {
      this.router.navigate(['/cart']);
    } else {

      this.name = localStorage.getItem('order_customer_name')
      this.email = shipping_details.email
      this.phone = shipping_details.phone
      this.address = shipping_details.address
      this.address_2 = shipping_details.address_2

      this.location = shipping_details.location
      this.pincode = shipping_details.pincode
      this.payment_method = (payment_details.payment_method === '2') ? 'Cash On Delivery' : 'Pay Via Online'
      this.order_id = localStorage.getItem("OID")
      this.codType = (localStorage.getItem("cod_type_id") == "1") ? "Pickup from Store" : "Home Delivery"
    }
    let emptyCart = []
    localStorage.clear();
    this.cart['cart'] = JSON.parse(JSON.stringify(this.cartService.cart));
    this.cart['cartSubTotal'] = JSON.parse(JSON.stringify(this.cartService.cartSubTotal))
    this.cart['cartTaxTotal'] = JSON.parse(JSON.stringify(this.cartService.cartTaxTotal))
    this.cart['roundOff'] = JSON.parse(JSON.stringify(this.cartService.roundOff))
    this.cart['cartTotal'] = JSON.parse(JSON.stringify(this.cartService.cartTotal))

    this.cartService._cart.next(emptyCart);
    this.cartService.updateCartTotal();
  }

  ngOnDestroy() {
    let emptyCart = []
    localStorage.clear();
    this.cartService._cart.next(emptyCart);
    this.cartService.updateCartTotal();
  }
}
