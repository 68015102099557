<div class="thank-wrap">
  <div class="container">
    <div class="row">
      <div class="col-md-8 col-lg-9">
        <div class="d-flex align-items-center">
          <h1 class="text-success mr-2"><i class="fa fa-2x fa-check-circle"></i></h1>
          <h4><small class="d-block mb-2">Order ID: {{order_id}}</small><span class="d-block">Thank you, {{name}}!</span>
          </h4>
        </div>
        <p class="border-top border-bottom pt-3 pb-3 mt-3 mb-3">Your order is confirmed. {{ messagecheck }}.
          <!-- <br> -->
          <!-- We've accepted your order
          and we're getting it ready. -->
        </p>
        <div class="row">
          <div class="col-md-6 mb-3 mb-md-0">
            <h6>Shipping Address</h6>
            <hr>
            <p>
              <b>{{name}}</b><br> {{address}}, {{address_2}}
              <br> {{location}}, {{pincode}}<br>
              Email: {{email}} <br>
              Phone: {{phone}}
            </p>
          </div>
          <div class="col-md-6 mb-3 mb-md-0">
            <h6>Payment Method</h6>
            <hr>
            <p>Cash On Delivery - {{codType}}</p>
            <!-- <p></p> -->
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-3 mb-3 mb-md-0">
            <a routerLink="/" class="btn btn-outline-primary"><i class="fa fa-chevron-left"></i>&nbsp;Back to Home</a>
          </div>
        </div>
      </div>

      <div class="col-md-4 col-lg-3 cart-summry" [hidden]="cart && cart.hasOwnProperty('cart')&&!cart.cart.length">
        <div class="card">
          <app-sidebar-widget [is_disable_checkout]=is_disable_checkout [cart_data]=cart></app-sidebar-widget>
        </div>
      </div>

    </div>
  </div>
</div>
