import { Component, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { CategoryService } from './service/category.service'
import { from } from 'rxjs';
import * as $ from 'jquery'
import { AppService } from './service/app.service';
import { TitleService } from './service/title.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(private appService: AppService, private title: TitleService) {}

  async ngOnInit() {
    this.loadStoreInfo()
  }

  loadStoreInfo() {
    this.appService.getInfo().subscribe((res) => {
      // console.log(res);
    })
  }

  onActivate(event) {
    // let scrollToTop = window.setInterval(() => {
    //     let pos = window.pageYOffset;
    //     if (pos > 0) {
    //         window.scrollTo(0, pos - 20); // how far to scroll on each step
    //     } else {
    //         window.clearInterval(scrollToTop);
    //     }
    // }, 16);
    window.scrollTo({
      top: 0,
      left: 100,
      behavior: 'smooth'
    });
}

}
