import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from '../service/cart.service';
import { PaymentService } from '../service/payment.service';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { Globals } from "../service/global"
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl
} from "@angular/forms";
import { TitleService } from '../service/title.service';
@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.css']
})
export class PaymentMethodComponent implements OnInit {
  public name;
  public email
  public phone
  public location
  public address
  public address_2

  public pincode
  public is_disable_checkout: boolean = false;
  public is_disbale_checkout_save: boolean = false;
  public is_nil_stock_orders_cart: boolean = false;
  public form: FormGroup;
  constructor(private global: Globals,
    private snackbar: MatSnackBar,
    private router: Router,
    public cartService: CartService,
    private paymentService: PaymentService,
    private fb: FormBuilder,
    private title: TitleService,
    private spinner: NgxSpinnerService) { }

  async ngOnInit() {
    this.title.setTitle('Select Payment Method')
    this.form = this.fb.group({
      payment_method: [2, Validators.compose([Validators.required])],
      cod_type_id: [1, Validators.compose([Validators.required])],
    });
    
    let response = await this.global.is_nil_stock_orders_cart.toPromise();
    this.is_nil_stock_orders_cart = response['is_nil_stock_orders_cart']
    //For rightbar widget
    await this.cartService.updateCartTotal()
    //End rightbar widget

    //check cart items are valid or not
    //check cart is not empty
    let check_cart_items = (JSON.parse(localStorage.getItem('cart'))) ? JSON.parse(localStorage.getItem('cart')) : [];

    //check shipping details
    let shipping_details = (JSON.parse(localStorage.getItem('shipping_details'))) ? JSON.parse(localStorage.getItem('shipping_details')) : "";
    let isData: boolean = true;
    // if (shipping_details) {
    //   if (shipping_details.name === '' || shipping_details.phone === '' || shipping_details.email === '' || shipping_details.location === '' || shipping_details.address === '' || shipping_details.address_2 === '' || shipping_details.pincode === '') {
    //     isData = false
    //   }
    // }

    if (shipping_details) {
      if (shipping_details.name === '' || shipping_details.phone === '' || shipping_details.location === '' || shipping_details.address === '' || shipping_details.pincode === '') {
        isData = false
      }
    }

    //check items are in stock
    // let res = (this.cartService.cart).filter(x => x.is_stock !== true);
    let res = (this.cartService.cart).filter(x => x.is_stock === false);
    this.is_disable_checkout = (res.length > 0) ? true : false

    if (check_cart_items.length === 0 || !isData || this.is_disable_checkout) {
      this.router.navigate(['/cart']);
    } else {
      this.name = shipping_details.name
      this.email = shipping_details.email
      this.phone = shipping_details.phone
      this.address = shipping_details.address
      this.address_2 = shipping_details.address_2
      this.location = shipping_details.location
      this.pincode = shipping_details.pincode
    }
  }

  async paymentDetails() {
    this.spinner.show();
    //check contain items
    let check_cart_items = (JSON.parse(localStorage.getItem('cart'))) ? JSON.parse(localStorage.getItem('cart')) : [];

    //check items are in stock
    // let res = (this.cartService.cart).filter(x => x.is_stock !== true);
    let res = (this.cartService.cart).filter(x => x.is_stock === false);

    this.is_disable_checkout = (res.length > 0) ? true : false

    if (check_cart_items.length === 0 || this.is_disable_checkout) {
      this.spinner.hide();
      this.router.navigate(['/cart']);
    }
    if (!this.form.valid) {
      this.spinner.hide();
      return;
    } else {
      // this.is_disable_checkout = true;
      this.is_disbale_checkout_save = true
      const config = new MatSnackBarConfig();
      config.panelClass = ['snackbar'];
      config.duration = 5000

      localStorage.setItem("payment_details", JSON.stringify(this.form.value))
      let total_qty = 0;
      (this.cartService.cart).filter(val => { total_qty += val.qty })
      let data = {
        "customer_name": this.name,
        "customer_mobile": this.phone,
        "email_id": this.email,
        "address": this.address,
        "address_2": this.address_2,
        "location": this.location,
        "pincode": this.pincode,
        "cart_payment_method_id": this.form.controls.payment_method.value,    // 1 Online Deleivery , 2 - Cash On Delivery
        "cod_type_id": this.form.controls.cod_type_id.value,    // 1 Online Deleivery , 2 - Cash On Delivery
        "total_items": this.cartService.cart.length,
        "total_qty": total_qty,
        "product_details": this.cartService.cart
      }
      if (!this.is_nil_stock_orders_cart) {
        await this.paymentService.stockVerification(this.cartService.cart).subscribe(
          async (result: any) => {
            //IS Exising Stock
            if (result) {
              await this.paymentProcess(data);
            } else {
              this.spinner.hide();
              this.router.navigate(['/cart']);
              this.snackbar.open('Some items in cart seems to be out of stock', 'Close', config);
            }
          },
          err => {
            // console.log(err);
            this.spinner.hide();
            this.router.navigate(['/cart']);
            this.snackbar.open('Something went wrong', 'Close', config);
          });
      } else {
        await this.paymentProcess(data);
      }
    }
  }

  async paymentProcess(data) {
    const config = new MatSnackBarConfig();
    config.panelClass = ['snackbar'];
    config.duration = 5000
    data.product_details.map((value, index)=> delete value.image)
   
    await this.paymentService.placeOrder(data).subscribe(
      (result: any) => {
        //Order Success, redirect to thankyou page
        localStorage.setItem("OID", result.order_ref_id)
        localStorage.setItem("cod_type_id", result.cod_type_id) 
        localStorage.setItem("order_customer_name", result.customer_name) 
        this.router.navigate(['/thank-you']);
        this.spinner.hide();
      },
      err => {
        console.log(err.error.error);
        this.router.navigate(['/cart']);
        this.snackbar.open(err.error.error, 'Close', config);
        this.spinner.hide();
      });
  }

}
