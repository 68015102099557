import { Component, OnInit, Input } from '@angular/core';
import { CartService } from '../service/cart.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar-widget',
  templateUrl: './sidebar-widget.component.html',
  styleUrls: ['./sidebar-widget.component.css'],
})
export class SidebarWidgetComponent implements OnInit {
  @Input() is_disable_checkout: any;
  @Input() cart_data: any = undefined;
  public is_view_checkout: boolean = false
  public is_view_cart: boolean = false
  constructor(public cartDataService: CartService, private router: Router) { }

  ngOnInit(): void {
    if(this.router.url === '/cart'){
      this.is_view_checkout = true
    }
    if(this.router.url === '/checkout' || this.router.url === '/payment-method'){
      this.is_view_cart = true
    }
    // console.log(this.is_disable_checkout);


    if(this.cart_data) {

      this.cartDataService = this.cart_data
    }
  }

}
