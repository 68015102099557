import { Directive, ElementRef, HostListener } from '@angular/core';


@Directive({
  selector: '[msThreedigitdecimal]'
})
export class ThreedigitdecimalDirective {

  // Allow decimal numbers and negative values


   private regex: RegExp = new RegExp(/^\d{1,4}\.?\d{0,3}$/g);

  private regexforint: RegExp = new RegExp(/^\d{1,4}$/);

  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete'];

  constructor(private el: ElementRef) { }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    let current: string = this.el.nativeElement.value;

    let next: string = current.concat(event.key);
    if (next && !String(next).match(this.regex)) {
      if (this.el.nativeElement.selectionStart == 0 && this.el.nativeElement.selectionEnd == this.el.nativeElement.value.length) {
        let splitval = next.split(".");
        let decimalval = splitval[1]
        if (decimalval) {
          if (decimalval.length == 4) {
            if (String(decimalval).match(this.regexforint)) {
              return;

            } else {
              event.preventDefault();
            }
          } else {
            event.preventDefault();
          }
        } else {
          event.preventDefault();

        }
      } else {
        event.preventDefault();

      }
    }
  }

  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    e.preventDefault();
  }

}
