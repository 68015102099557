import { Component, Inject, OnInit } from '@angular/core';
import { ProductService } from '../service/product.service'
import { CartService } from '../service/cart.service'
import { environment } from 'src/environments/environment';
import { TitleService } from '../service/title.service';
import { Globals } from "../service/global"
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  slides = [
    // { img: "assets/img/bnr-wecare.jpg" }, 
    // { img: "assets/img/bnr-wecare_paroppadi.jpg" },
    { img: "assets/img/banner-01.jpg" },
    // { img: "assets/img/banner-02.jpg" },
    // { img: "assets/img/banner-03.jpg" },


  ];
  slideConfig = { autoplay: true };
  public products: any = [];
  isProductLoading: boolean = false;
  pageNumber: number = 1;
  totalProducts: number = 0;
  public limit: any = parseInt(environment.limit)
  is_show_more: boolean = false
  public is_nil_stock_orders_cart: boolean = false;
  public is_deactivate_orders_cart: boolean = false
  constructor(private global: Globals, protected productService: ProductService, public cartService: CartService, 
    private title: TitleService, public router: Router,public dialog: MatDialog,) { }
  async ngOnInit() {
    // this.onloadPopup()      // We Care - Ashokapuram
    await this.title.setTitle()
    await this.getProducts()

    let response = await this.global.is_nil_stock_orders_cart.toPromise();
    this.is_nil_stock_orders_cart = response['is_nil_stock_orders_cart']
    console.log(this.is_nil_stock_orders_cart)
   
  }


  getProducts() {
    this.isProductLoading = true
    this.productService.getProducts(this.pageNumber).subscribe((data: any) => {
      this.totalProducts = data.totaldata
      this.pageNumber++;
      this.isProductLoading = false
      this.products = [...this.products, ...data.data]

      this.is_show_more = (this.pageNumber <= data.pagesCount) ? true : false

    })
  }

  addToCart(item) {
    this.cartService.addToCart(item);
  }

  onloadPopup(): void {
    let dialogRef = this.dialog.open(popupDialog, {
      panelClass: 'custom-modalbox'

    });
    dialogRef.afterClosed().subscribe(result => {
    });
    
  }

  // async goToCart() {
  //   let orders_cart_response = await this.global.is_deactivate_orders_cart.toPromise();
  //   this.is_deactivate_orders_cart = orders_cart_response['is_deactivate_orders_cart']
  //   if (this.is_deactivate_orders_cart) {
  //     this.cartService.productDeactivate();
  //   } else {
  //     this.router.navigate(['/cart'])
  //   }

  // }
}


@Component({
  selector: 'popup-dialog',
  templateUrl: './popup.html',
  styleUrls: ['./home.component.css']
})

export class popupDialog implements OnInit {
  
  constructor(public dialogRef: MatDialogRef<popupDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {

  }
  
}
