<footer>
  <div class="container">
    <div class="row">
      <div class="col-12 text-center mt-3">
          <div class="alert alert-primary" role="alert">{{ storeMessage }}</div>
      </div>
      <div class="col-md-3 mb-4">
        <h6>About Company</h6>
        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque et tempus velit.</p> -->
         <p>Calicut BesPOS aims to supply non-toxic food products directly from farmers, promoting healthy consumption and supporting sustainable agriculture</p>
        <!-- <p>We Care - Swayam Sahaya Sangham, a unit of Infant Jesus Church, Ashokapuram in the aim of supplying nontoxic food products directly from the farmers.</p> -->
        <!-- <p>WE CARE - PAROPPADI, in the aim of supplying nontoxic food products directly from the farmers.</p> -->

      </div>
      <div class="col-md-3 mb-4">
        <h6>Address</h6>
        <!-- <p>Akshya Nagar 1st Block 1st Cross, Rammurthy nagar, Bangalore-560016</p> -->
        <!-- <p>We Care Swayam Sahaya Sangham
           LURITA, 63/495-9B, SV Colony,
           Balan K Nair Road Calicut</p> -->
           <p>Calicut BesPOS - HiLite
            Business park,
            Pandeerankavu, Kozhikode,
            Kerala 673014
           </p>
          <!-- <p>WE CARE - PAROPPADI
            NH766, Malaparamba, Kozhikode, Kerala 673009</p> -->


      </div>
      <div class="col-md-3 mb-4">
        <h6>Help</h6>
        <ul>
          <li><a routerLink="/terms-and-condition">Terms and Conditions</a></li>
          <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
          <li><a routerLink="/cancellation-policy">Cancellation Policy</a></li>
        </ul>
      </div>
      <div class="col-md-3 mb-4">
        <h6>Connect</h6>
        <ul>
          <li><i class="fa fa-phone-alt"></i>&nbsp;{{ storeMobile }}</li>
          <li><i class="fa fa-envelope"></i>&nbsp;{{ storeEmail }}</li>
          <li class="s-media">
            <a href="#" title="Facebook"><i class="fab fa-facebook-f"></i></a>
            <a href="#" title="Instagram"><i class="fab fa-instagram"></i></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center mb-4">
        <hr>
        <a href="https://www.sesametechnologies.net/web/product.html" rel="noopener" target="_blank">
          <!-- <img src="assets/img/logo.png" class="brand-logo" alt="BesPOS"> -->
          <img src="assets/img/bespos-logo.png" class="brand-logo" alt="BesPOS">


        </a>
      </div>
    </div>
  </div>
  <div class="bottom-footer-bar">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <p>Copyright &copy; 2021 {{ storeName }}. All rights reserved</p>
          <p>Website developed by <a href="https://www.sesametechnologies.net/" rel="noopener" target="_blank"><img
                src="assets/img/sesame.png" alt="Sesame Technologies"></a></p>
        </div>
      </div>
    </div>
  </div>
</footer>
