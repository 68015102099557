<div class="page-ttl-wrap">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1>Cancellation Policy</h1>
                <ul>
                  <li><a routerLink="/">Home</a></li>
                  <li>Cancellation Policy</li>
                </ul>
            </div>
        </div>
    </div>
</div>
<div class="page-cntnt-wrap">
    <div class="container">
        <div class="row">
            <div class="col-12">
                
                <h4>Order Cancellation</h4> 

                <p>A customer can cancel any order within 1 hour after the order is placed either online or by calling our customer service number or by sending an order through WhatsApp and receive full refund of whatever amount paid. </p>
                <p>We will not be accepting or entertaining any cancellation requests after 1 hour from the time when an order is placed in any of the ways mentioned above and in such cases no payments will be refunded to customer in any aspects.</p> 

                <p>Also If we suspect and found any fraudulent orders or transactions done by any customer which is not abiding the points mentioned in the terms and conditions for using this website, WECARE SWAYAM SAHAYA SANGAM has the sole discretion to cancel such orders at any stage of order processing.</p>


                <h4>Refund Policy</h4>
                <p>If you Pre-pay for any orders, you are only entitled for a refund in the following circumstances.</p>
                <ol>
                    <li>Your order packaging has been tampered or damaged at the time of delivery.</li>
                    <li>Wecare cancelling your order due to the following reasons Address provided by you is outside of our delivery area or if you fail to attend our confirmation call. </li>
                    <li>If WECARE SWAYAM SAHAYA SANGAM was not able to deliver the products which you ordered and confirmed.</li>
                </ol>
            </div>
        </div>
    </div>
</div>