import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Service } from './service'
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CategoryService extends Service {
  private readonly _categories = new BehaviorSubject<any>([]);
  readonly categories$ = this._categories.asObservable();

  constructor(private http: HttpClient) {
    super();
  }
  get categories() {
    return this._categories.getValue();
  }

  set categories(value) {
    this._categories.next(value);
  }


  getCategories() {
    return this.http.get(`${this.pasePath}shoppingcart/category`, {
      headers: {
        'unitid': environment.unitId,
      }
    });
  }
}
