import { Injectable } from '@angular/core';
import { Service } from './service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductService extends Service {

  constructor(private http: HttpClient) {
    super()
  }

  getProducts(page=1, search = '', category_id = '0') {
    return this.http.get(`${this.pasePath}shoppingcart/${page}`, {
      headers: {
        'unitid': environment.unitId,
        'limit': environment.limit,
        'search': search,
        'catid': category_id,
        'outletid' : environment.outletId
      }
    });
  }
}
