import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap, map } from 'rxjs/operators';
import { ProductService } from '../service/product.service';
import { Location } from '@angular/common';
import { combineLatest, empty } from 'rxjs';
import { CategoryService } from '../service/category.service';
import { CartService } from '../service/cart.service';
import { environment } from 'src/environments/environment';
import { TitleService } from '../service/title.service';
import { Globals } from "../service/global"

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {

  constructor(private global: Globals,
    private route: ActivatedRoute,
    private productService: ProductService,
    private categoryService: CategoryService,
    private router: Router,
    public cartService: CartService,
    private title: TitleService,
  ) { }
  public products: any = [];
  category: any = {}
  category_id: string;
  isProductLoading: boolean = false;
  pageNumber: number = 1;
  totalProducts: number = 0;
  public limit: any = parseInt(environment.limit)
  is_show_more: boolean = false
  params: any = {}
  public is_nil_stock_orders_cart: boolean = false;

  async ngOnInit() {
    combineLatest(this.route.params, this.route.queryParams)
      .pipe(map(params => ({ slug: params[0].slug, q: params[1].q || '' })))
      .subscribe(params => {
        this.reset()
        this.loadCategory(params)
      });
    let response = await this.global.is_nil_stock_orders_cart.toPromise();
    this.is_nil_stock_orders_cart = response['is_nil_stock_orders_cart']
  }
  reset() {
    this.category = {}
    this.params = {
      q: ''
    }
    this.products = []
    this.pageNumber = 1
    this.totalProducts = 0
  }

  getProducts() {
    this.isProductLoading = true
    this.productService.getProducts(this.pageNumber, this.params?.q, ((this.category?.id || 0) + '')).subscribe((data: any) => {
      this.pageNumber++;
      this.isProductLoading = false
      this.products = [...this.products, ...data.data]
      this.totalProducts = data.totaldata
      this.is_show_more = (this.pageNumber <= data.pagesCount) ? true : false

    })
  }

  loadCategory(params) {
    let slug = params.slug
    this.categoryService.categories$.subscribe(categories => {
      this.reset();
      if (categories.length === 0) {
        return;
      }
      this.params = params
      this.category = categories.find(category => category.name == slug);
      if (!this.category) {
        this.router.navigate(['/not-found'])
        return;
      }
      this.title.setTitle(this.category.name)
      this.getProducts();
    });
  }

  addToCart(item) {
    this.cartService.addToCart(item);
  }
}
