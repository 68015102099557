import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  constructor(private titleService: Title, private app: AppService) { }

  setTitle(pageName = '', separator = '|', storeName = this.app.storeName ) {
    this.titleService.setTitle(`${ storeName } ${ pageName.length > 0 ? separator : ''} ${pageName}`)
  }
}
