<div class="card-header">
  <h6>Summary ({{cartDataService.cart.length}} Item(s))</h6>
</div>
<div class="card-body">
  <dl class="dlist-align">
    <dt>Sub Total:</dt>
    <dd class="text-right mb-0"><i class="fa fa-rupee-sign"></i>&nbsp;{{cartDataService.cartSubTotal}}</dd>
  </dl>
  <dl class="dlist-align">
    <dt>Tax:</dt>
    <dd class="text-right mb-0"><i class="fa fa-rupee-sign"></i>&nbsp;{{cartDataService.cartTaxTotal}}</dd>
  </dl>
  <dl class="dlist-align" *ngIf="cartDataService.roundOff != 0">
    <dt>Round Off:</dt>
    <dd class="text-right mb-0"><i class="fa fa-rupee-sign"></i>&nbsp;{{cartDataService.roundOff}}</dd>
  </dl>
  <hr>
  <dl class="dlist-align mb-4">
    <dt>Total:</dt>
    <dd class="text-right mb-0"><i class="fa fa-rupee-sign"></i>&nbsp; {{cartDataService.cartTotal}}</dd>
  </dl>
  <div class="d-flex align-items-center justify-content-between
  ">
    <a routerLink="/" class="btn btn-outline-primary mr-3 d-md-none"><i class="fa fa-chevron-left"></i>&nbsp;Continue
      Shopping</a>
    <button routerLink="/checkout" *ngIf="is_view_checkout" class="btn btn-primary w-md-100" aria-label="Checkout"
      [disabled]="is_disable_checkout || cartDataService.cartTotal<= 0">Checkout&nbsp;<i class="fa fa-chevron-right"></i></button>
  </div>
  <button routerLink="/cart" *ngIf="is_view_cart" aria-label="View Cart" class="btn btn-primary btn-block"
    >View Cart&nbsp;<i class="fa fa-chevron-right"></i></button>
</div>
