import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ProductComponent } from './product/product.component';
import { CartComponent } from './cart/cart.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { PaymentMethodComponent } from './payment-method/payment-method.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { SearchComponent } from './search/search.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { TermsconditionComponent } from './termscondition/termscondition.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { CancellationPolicyComponent } from './cancellation-policy/cancellation-policy.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'products/:slug', component: ProductComponent },
  { path: 'cart', component: CartComponent },
  { path: 'checkout', component: CheckoutComponent },
  { path: 'payment-method', component: PaymentMethodComponent },
  { path: 'thank-you', component: ThankYouComponent },
  { path: 'search', component: SearchComponent},
  { path: 'terms-and-condition', component: TermsconditionComponent},
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'cancellation-policy', component: CancellationPolicyComponent },

  { path: '**', component: NotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
