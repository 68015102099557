import { v4 as uuidv4 } from 'uuid';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Service } from './service'
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService extends Service {

  constructor(private http: HttpClient, private snackbar: MatSnackBar) {
    super();
  }

  stockVerification(data) {
    let headers: any = {
      'unitid': environment.unitId,
      'outletid': environment.outletId,
    }
    return this.http.post(`${this.pasePath}shoppingcart/stockverification`, data, {
      headers
    });
  }

  placeOrder(data) {
    let headers: any = {
      'unitid': environment.unitId,
      'outletid': environment.outletId,
    }
    return this.http.post(`${this.pasePath}shoppingcart/order`, data, {
      headers
    });
  }
}
