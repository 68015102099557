<div class="page-ttl-wrap">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h1>Customer Information</h1>
        <ul>
          <li><a routerLink="/">Home</a></li>
          <li><a routerLink="/cart">Cart</a></li>
          <li>Customer Information</li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="checkout-wrap">
  <div class="container">
    <div class="row">

      <div class="col-md-8 col-lg-9" [formGroup]="form" *ngIf="this.form">
        <h5>Please fill out the form below</h5>
        <hr>
        <div class="form-row">
          <div class="col-md-6 form-group">
            <input matInput autocomplete="off" class="form-control" id="name" name="name" maxlength="100"
              placeholder="Name *" [formControl]="form.controls['name']" aria-label="Name" #nameinputcontroller>
          </div>
          <div class="col-md-6 form-group">
            <input matInput autocomplete="off" type="tel"
              oninput="this.value = this.value.replace(/[^0-9+, ]/g, '').replace(/(\..*)\./g, '$1');"
              class="form-control" id="phone" name="phone" minlength="6" maxlength="16" placeholder="Phone Number *"
              [formControl]="form.controls['phone']" aria-label="Phone Number" readonly>
            <!-- <small class="red-clr" *ngIf="form.controls['phone'].hasError('minlength')">
                Minimum 6 number required.
            </small> -->
            <span class="searchclear1" *ngIf="form.controls['phone'].value" (click)="reset()"><i
                class="far fa-times-circle"></i></span>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-12 form-group">
            <input matInput autocomplete="off" type="email" class="form-control" id="email" name="email" maxlength="100"
              placeholder="Email Id " [formControl]="form.controls['email']"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" aria-label="Email">
            <small class="red-clr"
              *ngIf="form.controls['email'].hasError('email') || form.controls['email'].hasError('pattern') ">

              Invaild email address
            </small>
          </div>
        </div>
        <div class="form-row">
          <!-- <div class="col-md-12 form-group">
            <textarea matInput autocomplete="off" rows="4" class="form-control" id="address" name="address"
              maxlength="100" placeholder="Address *" [formControl]="form.controls['address']" aria-label="Address" ></textarea>
          </div> -->
          <div class="col-md-6 form-group">
            <input matInput autocomplete="off" class="form-control" id="address" name="address" maxlength="100"
              placeholder="Address 1 *" [formControl]="form.controls['address']" aria-label="Address">
          </div>
          <div class="col-md-6 form-group">
            <input matInput autocomplete="off" class="form-control" id="address_2" name="address_2" maxlength="100"
              placeholder="Address 2" [formControl]="form.controls['address_2']" aria-label="Address 2">
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-6 form-group">
            <input matInput autocomplete="off" class="form-control" id="location" name="location" maxlength="100"
              placeholder="Location *" [formControl]="form.controls['location']" aria-label="Location">
          </div>
          <div class="col-md-6 form-group">
            <input matInput autocomplete="off" type="tel" OnlyNumber="true" class="form-control" id="pincode"
              name="pincode" minlength="6" maxlength="6" placeholder="Pincode *" (keyup)="checkPincode($event)"
              [formControl]="form.controls['pincode']" aria-label="Pincode">

            <small class="red-clr" *ngIf="form.controls['pincode'].hasError('minlength')">
              required minimum 6 number.
            </small>
            <small class="red-clr" *ngIf="!is_picode_available">
              Sorry. We don't deliver orders to the entered pin code.
            </small>
          </div>
        </div><hr>

        <div>
          # Delivery in Kozhikode Corporation limit only & Charges will be applicable on Home delivery
        </div>

        <div class="row align-items-center mb-3 mb-md-0 mt-10">
          <div class="col-md-12 col-lg-6 text-left mb-3 mb-md-0 red-clr">{{(!form.valid)?'* Please fill all required
            field(s)':''}}</div>
          <div class="col-md-12 col-lg-6 text-right">
            <button routerLink="/cart" class="btn btn-outline-primary mr-3 btn-size" aria-label="Return to Cart"><i
                class="fa fa-chevron-left"></i>&nbsp;Return
              to Cart</button>
            <button [disabled]="!form.valid || is_disable_checkout || !is_picode_available" routerLink="/payment-method"
              (click)="shippingDetails()" aria-label="Continue Checkout" class="btn btn-primary btn-size">Continue&nbsp;<i
                class="fa fa-chevron-right"></i></button>
          </div>
         
        </div>
      </div>

      <div class="col-md-4 col-lg-3 cart-summry d-none d-md-block" [hidden]="!cartService.cart.length">
        <div class="card">
          <app-sidebar-widget [is_disable_checkout]=is_disable_checkout></app-sidebar-widget>
        </div>
      </div>

     
    </div>

   

    <div *ngIf="is_disable_checkout" class="col-md-12 disabled-text">Cart contain out of stock items. Please clear
      it
      before doing checkout.</div>

  </div>