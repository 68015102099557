<div class="container pt-5 pb-5">
    <div class="row justify-content-center">
        <div class="col-md-6">
            <div class="card">
                <div class="card-body notFound-card">
                    <h1>404</h1>
                    <h2>Not Found</h2>
                    <p>We are sorry but requested page does not exist. Try again later or you can <a routerLink="/">return to base.</a></p>
                </div>
            </div>
        </div>
    </div>
</div>
