import { Injectable } from '@angular/core';
import { Service } from './service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppService extends Service {

  private name = environment.store.name;
  private email = environment.store.email;
  private mobile = environment.store.mobile;
  private message = environment.store.message;
  constructor(private http: HttpClient) { super() }

  getInfo() {
    return this.http.get(`${this.pasePath}shoppingcart/storeinfo`, {
      headers: {
        'unitid': environment.unitId,
      }
    });
  }
  get storeName() {
    return this.name;
  }
  get storeEmail() {
    return this.email;
  }
  get storeMobile() {
    return this.mobile;
  }
  get storeMessage() {
    return this.message;
  }

}
