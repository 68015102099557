import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Service } from './service'
import { environment } from 'src/environments/environment';

@Injectable()
export class Globals extends Service {
    public is_nil_stock_orders_cart
    public is_deactivate_orders_cart
    constructor(private http: HttpClient) {
        super();
        this.is_nil_stock_orders_cart = this.checkIsShowStock();
        this.is_deactivate_orders_cart = this.deactivateOrderCart();
    }

    checkIsShowStock() {
        return this.http.get(`${this.pasePath}shoppingcart/ordertype/isnilstock`, {
            headers: {
                'unitid': environment.unitId,
                'limit': environment.limit,
                'outletid': environment.outletId
            }
        });
    }

    deactivateOrderCart() {
        return this.http.get(`${this.pasePath}shoppingcart/ordertype/deactivecart`, {
            headers: {
                'unitid': environment.unitId,
                'limit': environment.limit,
                'outletid': environment.outletId
            }
        });
    }

};