<div class="page-ttl-wrap">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h1>{{category?.name || params?.q}}</h1>
        <ul>
          <li><a routerLink="/">Home</a></li>
          <li [hidden]="!category?.name">{{ category?.name }}</li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="product-pg-wrp">
  <div class="container">
    <div class="container-fluid">
      <div class="col-sm-12 text-center">
        <div class="spinner-border lasdas" role="status" [hidden]="(!isProductLoading || !(products.length < 1))">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
    <div class="row product-wrap">
      <div class="col-6 col-md-4 col-lg-3 d-flex" *ngFor="let product of products;let i = index">
        <div class="card prod-item" [class.out-of-stock]="product.current_stock == 0 && !is_nil_stock_orders_cart" >
          <!-- product.current_stock == 0 -->
          <!-- <span class="cart-item-count">1</span> -->
          <img [src]="product.image ? product.image : 'assets/img/products/default.jpg'" [alt]="product.product_name">
          <div class="card-body">
            <div class="prod-info">
              <p>{{ product.product_name }}</p>
              <h6>{{ product.sales_price | currency:'INR' }}</h6>
            </div>
            <div class="card-footer">
              <button type="button" class="btn btn-sm btn-primary" [disabled]='product.current_stock == 0 && !is_nil_stock_orders_cart'
                (click)="addToCart(product)">
                <!-- product.current_stock == 0 -->
                Add to cart&nbsp;<i class="fa fa-shopping-cart"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-md-12 mb-4" [hidden]="products.length && !isProductLoading"> -->
        <div class="col-md-12 mb-4" *ngIf="!products.length && !isProductLoading">
        <p>No Products Found</p>
      </div>
    </div>
    <div class="container-fluid" [hidden]="!is_show_more">
      <div class="col-sm-12 mb-4 text-center">
        <button class="btn btn-primary" style="text-align: center;" title="Submit"
          [disabled]='isProductLoading' (click)="getProducts()">
          Load More
          <div class="spinner-border" role="status" style="width: 1.5rem;height: 1.5rem;" [hidden]="!isProductLoading">
            <span class="sr-only">Loading...</span>
          </div>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- <div class="floating-cart">
  <a routerLink="/cart">
    <span class="badge badge-pill badge-danger">{{ cartService.cart.length }}</span>
    <img src="assets/img/cart-edit.png" alt="">
  </a>
</div> -->
