<h1 mat-dialog-title>Enter your mobile number</h1>
<div class="field-wrap" mat-dialog-content>
    <form [formGroup]="form" (ngSubmit)="save(form)">
        <div class="form-group">
            <input autocomplete="off" type="tel"
            oninput="this.value = this.value.replace(/[^0-9+, ]/g, '').replace(/(\..*)\./g, '$1');"
            class="form-control" id="phone" name="phone" minlength="10" maxlength="14" placeholder="Mobile Number *"
            [formControl]="form.controls['phone']" aria-label="Mobile Number" >
            <small class="red-clr" *ngIf="form.controls['phone'].hasError('minlength')">
            Minimum 10 number required.
            </small>
        </div>
    </form>
</div>
<div mat-dialog-actions class="d-flex justify-content-end">
    <button class="btn btn-outline-primary mr-3" (click)="onNoClick()">Back</button>
    <button class="btn btn-primary" [disabled]="!form.valid" (click)="save(form)" type="submit" >OK</button>
</div>
