import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ProductComponent } from './product/product.component';
import { CartComponent } from './cart/cart.component';
import { CheckoutComponent, mobileNoPopupDialog } from './checkout/checkout.component';
import { PaymentMethodComponent } from './payment-method/payment-method.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { TokenInterceptorService } from './service/httpInterceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatRadioModule } from '@angular/material/radio';
import { SidebarWidgetComponent } from './sidebar-widget/sidebar-widget.component';
import { SearchComponent } from './search/search.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { OnlyNumber } from './directive/numeric.directive';
import { ThreedigitdecimalDirective } from './directive/threedigitdecimal.directive';
import { NumberonlywithoutcontrolDirective } from './directive/numberonlywithoutcontrol.directive';
import { NgxSpinnerModule } from 'ngx-spinner';
import { Globals } from './service/global';
import { NotFoundComponent } from './not-found/not-found.component';
import { TermsconditionComponent } from './termscondition/termscondition.component';
import { CancellationPolicyComponent } from './cancellation-policy/cancellation-policy.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { StickyNavModule } from 'ng2-sticky-nav';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProductComponent,
    CartComponent,
    CheckoutComponent,
    PaymentMethodComponent,
    ThankYouComponent,
    SidebarWidgetComponent,
    SearchComponent,
    HeaderComponent,
    FooterComponent,
    OnlyNumber,
    ThreedigitdecimalDirective,
    NumberonlywithoutcontrolDirective,
    NotFoundComponent,
    TermsconditionComponent,
    CancellationPolicyComponent,
    PrivacyPolicyComponent,
    mobileNoPopupDialog
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SlickCarouselModule,
    HttpClientModule,
    MatSnackBarModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatRadioModule,
    NgxSpinnerModule,
    StickyNavModule,
    MatDialogModule
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true }, Globals],
  bootstrap: [AppComponent]
})
export class AppModule { }
