<div class="page-ttl-wrap">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1>Terms & Condition</h1>
                <ul>
                  <li><a routerLink="/">Home</a></li>
                  <li>Terms & Conditions</li>
                </ul>
            </div>
        </div>
    </div>
</div>
<div class="page-cntnt-wrap">
    <div class="container">
        <div class="row">
            <div class="col-12">
                
<p>WECARE SWAYAM SAHAYA SANGAM is the licensed owner of the brand “wecare” and www.wecareretail.com is the official website of WECARE SWAYAM SAHAYA SANGAM for selling its various products in online mode, under the brand name “wecare”.
Anybody visiting this website as a visitor or customer are a advised to read the terms and conditions for using this website before purchasing any product from here.  By accessing our website and enjoying the services provided by the website, you are automatically agreeing to the terms and conditions provided here.</p>
    <ol>
        <li>License and site access</li>
        WECARE SWAYAM SAHAYA SANGAM grants all visitors only the permission to access this website for viewing and purchasing our products online.  Visitors shall not make use of this website for any kind of personal use or download it (other than page caching) or copy or modify or reuse any part of it without our written consent and approval. 

        <li>Eligibility</li>
        Any person is eligible to use this website provided they should be competent based on the section  II of the Indian Contract Act 1872,  which specifies that every person is found competent under this act, provided he or she has attained  the age of 18 years, and if at least 13 years of age he or she may use the site only under the supervision of a parent or legal guardian who agrees to be  bound by these term of use. 

        <li>About the products sold in this website</li>
        All products sold in this website are outsourced from various entrepreneurs and farmers after our own quality checks. WECARE SWAYAM SAHAYA SANGAM or any of its members do not have any guarantee on the quality of such products sold either through website or through physical outlets. WECARE SWAYAM SAHAYA SANGAM is working as a marketing agency for such entrepreneurs and farmers with the intention to promote their products in front of public. If customers raise any kind of quality issues we will inform the same to the respective supplier.

        <li>Pricing of products in this website</li>
        Pricing of all products listed in this website  www.wecareretail.com  is excluding the GST. During the billing time, customer is bound to pay the applicable GST based on Govt. norms. 
        Even though most of the product pricing remains the same when compared to the pricing displayed in the website during the time of ordering and time of delivering, some of them may vary based on the fluctuations in the market which are beyond our control. 
        In such cases, the additional charges will be collected from customer in case if pricing is increased and in the case if pricing is reduced excess payment shall be refunded to customer during the time of delivery of the respective order. Please note that WECARE SWAYAM SAHAYA SANGAM has the complete discretion in taking a decision on this kind of matters.

        <li>Complaint handling</li>
        Customers has the right to submit a complaint against the quality of any products that we sold but same should be done based on following terms.

        <ul>
            <li>For all complaints bills are to be produced without fail.</li>
            <li>Complaints on products purchased and kept for a long time after the expiry period shall not be entertained.</li>
            <li>If any products found not fit for consumption or unsatisfied, same should be informed to our customer care number or email within 24 hours from the time of purchase.</li>
            <li>Complaints done after consuming more than 1/4th of total quantity of the product purchased shall not be entertained in any aspects.</li>
            <li>All complaints will be entertained only if customer have sufficient supporting data and proof </li>
        </ul>

        <li>Jurisdiction</li>
        All disputes arising out of  or in doing business  on www.wecareretail.com  shall be amicably settled at the first instance by mutual discussions and negotiations. In the event the dispute is not  resolved then the  same may be referred to arbitration in accordance with the provisions of the arbitration and conciliation  Act 1996 or  any  enactment  of statutory modifications. There of the arbitration proceedings shall be in the English language and shall be held in Ernakulam. The award of the arbitral tribunal shall be final binding upon the parties and  no appeal against  the same shall lie to any court. The court at competent jurisdiction in Ernakulum shall have exclusive jurisdiction  over any dispute, differences or claims arising out of this agreement. 

        <li>Home Delivery of products</li>
        For home delivery of products, we are depending third party delivery companies. All matters related to the delivery of products will be completely the responsibility of such companies with which we have signed agreements for the same.

        <li>Right to modify Terms and conditions of service</li>
        WECARE SWAYAM SAHAYA SANGAM reserves unconditional right to modify terms and conditions at transacting business on www.wecareretail.com without any prior notification to customer and their consent.

    </ol>
            </div>
        </div>
    </div>
</div>
