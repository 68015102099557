declare var jQuery: any;

import { Directive, ElementRef, HostListener,Renderer2,OnInit,Input } from '@angular/core';
import { timeout } from 'rxjs/operators';
import { NgControl } from "@angular/forms";
@Directive({
  selector: '[msNumberonlywithoutcontrol]'
})
export class NumberonlywithoutcontrolDirective implements OnInit {

  private regex: RegExp = new RegExp(/^\d{1,4}$/);
private regexforint: RegExp = new RegExp(/^\d{1,4}$/);
@Input() decimallimit;

// Allow key codes for special events. Reflect :
// Backspace, tab, end, home
//for all +ve and -ve fractions
private specialKeys: Array<string> = [ 'Backspace', 'Tab', 'End', 'Home','ArrowLeft','ArrowRight','Delete'];

//for all +ve  fractions


  constructor(private el: ElementRef,private renderer: Renderer2) { }
  ngOnInit() {
    setTimeout(() => {
      if(this.el.nativeElement.value == ""){
        this.el.nativeElement.value=0;
  
      }
    }, 100);
  
  }

  @HostListener('keydown', [ '$event' ])
  onKeyDown(event: KeyboardEvent) {

  if (this.specialKeys.indexOf(event.key) !== -1) {
    return;
  }
  
  // Allow Backspace, tab, end, and home keys
  // if(this.el.nativeElement.getAttribute('msNumberonlywithoutcontrol')== "positive" || this.el.nativeElement.getAttribute('msNumberonlywithoutcontrol')== "numeric")
  // {
  // if (this.specialKeys1.indexOf(event.key) !== -1) {
  // return;
  // }
  // }
  // else{
  //   if (this.specialKeys.indexOf(event.key) !== -1) {
  //     return;
  //     }
  // }
  
  let current: string = this.el.nativeElement.value;
  let next: string = current.concat(event.key);
  if(this.el.nativeElement.getAttribute('msNumberonlywithoutcontrol') == "numeric"){
  
    if (next && !String(next).match(this.regexforint)) {
      event.preventDefault();
      }
  }
  
  else if(this.el.nativeElement.getAttribute('msNumberonlywithoutcontrol') == "positive"){
  
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
      }
  }
  
  
  else{
    if(event.key=="-"){
  
      this.el.nativeElement.value=(-1 * this.el.nativeElement.value);
      event.preventDefault();
    }
    if (next && !String(next).match(this.regex)) {
  
      event.preventDefault();
      }else{
        
      }
  }
  
  }
  
  @HostListener('keyup', [ '$event' ])
  onKeyUp(event: KeyboardEvent) {

    if(event.key == "Backspace"){
        let current1 = this.el.nativeElement.value;
        if(parseInt(current1.length) == 0){
         // this.el.nativeElement.value=0;
          }
        }
      
  }
  
  
  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }
  
  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    e.preventDefault();
  }
  
  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    e.preventDefault();
  }

}
