import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Service } from './service'
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService extends Service {

  constructor(private http: HttpClient,) {
    super();
   }

   checkPincodeAvailable(data) {
    let headers: any = {
      'unitid': environment.unitId,
      'outletid': environment.outletId,
    }
    return this.http.get(`${this.pasePath}shoppingcart/checkpincode/${data}`, {
      headers
    });
  }
}
