<div class="home-wrpr">
    <div class="container">
        <div class="row">
            <div class="col-12 banner-wrap">
              <ngx-slick-carousel class="carousel"
                  #slickModal="slick-carousel"
                  [config]="slideConfig"
                >
                  <div ngxSlickItem *ngFor="let slide of slides" class="slide">
                        <img src="{{ slide.img }}" alt="" width="100%">
                  </div>
              </ngx-slick-carousel>
            </div>
        </div>
        <div class="row">
            <div class="col-12 prod-sec-ttl text-center">
                <h3><span>Featured Products</span></h3>
            </div>
        </div>

        <div class="container-fluid">
          <div class="col-sm-12 text-center">
              <div class="spinner-border" role="status" [hidden]="(!isProductLoading || !(products.length < 1))">
                <span class="sr-only">Loading...</span>
              </div>
          </div>
        </div>
        <div class="row product-wrap">
            <div class="col-6 col-md-4 col-lg-3 d-flex" *ngFor="let product of products;let i = index">
                <div class="card prod-item" [class.out-of-stock]="product.current_stock == 0 && !is_nil_stock_orders_cart" >
                    <!-- product.current_stock == 0 -->
                    <!-- <span class="cart-item-count">1</span> -->
                    <img [src]="product.image ? product.image : 'assets/img/products/default.jpg'" [alt]="product.product_name">
                    <div class="card-body">
                        <div class="prod-info">
                            <p>{{ product.product_name }}</p>
                            <h6>{{ product.sales_price | currency:'INR' }}</h6>
                        </div>
                        <div class="card-footer">
                            <button type="button" class="btn btn-sm btn-primary" aria-label="Add to Cart" [disabled]='product.current_stock == 0 && !is_nil_stock_orders_cart' (click)="addToCart(product)">
                                <!--product.current_stock == 0  -->
                              Add to cart&nbsp;<i class="fa fa-shopping-cart"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!products.length && !isProductLoading" class="col-md-12 mb-4">
                <p style="text-align: center;">No data found. Please check back later.</p>
              </div>

          </div>
          <div class="container-fluid" [hidden]="!is_show_more">
            <div class="col-sm-12 mb-4 text-center">
                <button class="btn btn-primary" style="text-align: center;" aria-label="Load More" title="Submit" [hidden]="!products.length" [disabled]='isProductLoading' (click)="getProducts()">
                    Load More
                    <div class="spinner-border" role="status" style="width: 1.5rem;height: 1.5rem;" [hidden]="!isProductLoading">
                      <span class="sr-only">Loading...</span>
                    </div>
                </button>
            </div>
          </div>
        <div class="row mt-2 mt-md-2 mb-2">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-4 common-facts">
                                <p class="mb-3"><i class="fa fa-2x fa-tags"></i></p>
                                <h5 class="mb-2">Genuine Products</h5>
                                <p>It is always our highest priority at WeCare to provide maximum satisfaction for customers who choose to buy our genuine products.</p>
                            </div>
                            <div class="col-md-4 common-facts">
                                <p class="mb-3"><i class="fa fa-2x fa-lock"></i></p>
                                <h5 class="mb-2">Secure Payment</h5>
                                <p>You can conveniently pay by direct debit, credit card, or online. We also have cash on delivery options to avoid any hassle.</p>
                            </div>
                            <div class="col-md-4 common-facts">
                                <p class="mb-3"><i class="fa fa-2x fa-award"></i></p>
                                <h5 class="mb-2">Quality Assured</h5>
                                <p>We are committed to our word of delivering the best quality products.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="floating-cart">
    <a  (click)="goToCart()">
        <span class="badge badge-pill badge-danger">{{ cartService.cart.length }}</span>
        <img src="assets/img/cart-edit.png" alt="">
    </a>
</div> -->
