import { Component, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl
} from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CartService } from '../service/cart.service';
import { CategoryService } from '../service/category.service'
import { AppService } from '../service/app.service';
import { Globals } from "../service/global"
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
  @ViewChild('shoppingCartToggleButton') shoppingCartToggleButton: ElementRef;
  @ViewChild('shoppingCart') shoppingCart: ElementRef;
  @ViewChild('q') q: ElementRef;
  @ViewChild('nav') nav: ElementRef;
  @ViewChild('widgetsContent', { read: ElementRef })
  public widgetsContent: ElementRef<any>;
  isLoaded = false;
  title = 'store-front';
  is_cart_is_open: boolean = false;
  categories: any = []
  public storeEmail = ''
  public storeMobile = ''

  public form: FormGroup;
  public is_req: boolean = false
  okayNav: any = null
  customStickyLogic: boolean
  public is_deactivate_orders_cart
  constructor(
    private renderer: Renderer2,
    protected CategoryService: CategoryService,
    private router: Router,
    private fb: FormBuilder,
    public cartService: CartService,
    private route: ActivatedRoute,
    private app: AppService,
    private global: Globals,) {
    this.storeEmail = app.storeEmail
    this.storeMobile = app.storeMobile
    this.renderer.listen('window', 'click', (e: Event) => {
      /**
       * Only run when toggleButton is not clicked
       * If we don't check this, all clicks (even on the toggle button) gets into this
       * section which in the result we might never see the menu open!
       * And the menu itself is checked here, and it's where we check just outside of
       * the menu and button the condition abbove must close the menu
       */
      if (!this.shoppingCart)
        return;

      if (!this.shoppingCartToggleButton.nativeElement.contains(e.target) && !this.shoppingCart.nativeElement.contains(e.target)) {
        this.is_cart_is_open = false;
      }
    });

    this.renderer.listen('window', 'resize', (e: Event) => {
      if ($(window).width() > 768) {
        setTimeout(() => {
          if (this.okayNav) {
            let vm = this;
            $('.okayNav li a').on('click', function () {
              vm.okayNav.okayNav('closeInvisibleNav');
            });
            this.okayNav.okayNav('recalcNav');
            return;
          }
          this.setOkayNav()
        })

        setTimeout(() => {
          this.isLoaded = true;
        }, 1200);
      } else {
        if (this.okayNav) {
          this.okayNav.okayNav('destroy');
          this.okayNav = null;
        }
        setTimeout(() => {
          this.isLoaded = true;
        }, 1200);
      }
    });



  }

  public scrollRight(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft + 100), behavior: 'smooth' });
  }

  public scrollLeft(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft - 100), behavior: 'smooth' });
  }

  toggleCart() {
    this.is_cart_is_open = !this.is_cart_is_open

  }

  async deactivateOrderCart() {
    let response = await this.global.is_deactivate_orders_cart.toPromise();
    this.is_deactivate_orders_cart = response['is_deactivate_orders_cart']
    if (this.is_deactivate_orders_cart) {
      this.cartService.productDeactivate()
    } else {
      this.router.navigate(['/cart'])
    }
  }

  async deactivateOrderCheckout() {
    let response = await this.global.is_deactivate_orders_cart.toPromise();
    this.is_deactivate_orders_cart = response['is_deactivate_orders_cart']
    if (this.is_deactivate_orders_cart) {
      this.cartService.productDeactivate()
    } else {
      this.router.navigate(['/checkout'])
    }
  }

  ngOnInit() {
    this.form = this.fb.group({
      search: [null, Validators.compose([Validators.required])],
    });
    this.route.queryParams
      .subscribe(params => {
        if (params.search) {
          this.q.nativeElement.value = params.search;
        } else {
          if (this.q)
            this.q.nativeElement.value = ''
        }
      }
      );

    this.getCategories()
  }

  setOkayNav() {
    this.okayNav = (<any>$('.okayNav')).okayNav({
      toggle_icon_content: '<span></span><span></span><span></span>',
    })
    window.dispatchEvent(new Event('resize'));
  }

  getCategories() {
    this.CategoryService.getCategories()
      .subscribe((data) => {
        this.CategoryService.categories = data
        this.categories = data
        setTimeout(() => {
          this.setOkayNav()
        })
      })
  }
  getCategoryRoute(category) {
    return ['/products', category.name]
  }
  search() {
    if (!this.form.valid) {
      this.is_req = true
      return;
    } else {
      localStorage.setItem("search", this.form.controls.search.value)
      //this.form.controls.search.setValue("")
      this.router.navigate(['/search'], { queryParams: { search: this.form.controls.search.value } });
    }
  }

  async goToCart() {
    let orders_cart_response = await this.global.is_deactivate_orders_cart.toPromise();
    this.is_deactivate_orders_cart = orders_cart_response['is_deactivate_orders_cart']
    if (this.is_deactivate_orders_cart) {
      this.cartService.productDeactivate();
    } else {
      this.router.navigate(['/cart'])
    }

  }
}
