<div class="page-ttl-wrap">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h1>Your Cart</h1>
        <ul>
          <li><a routerLink="/">Home</a></li>
          <li>Your Cart</li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="cart-wrap">
  <div class="container">
    <div class="row">
      <div class="col-md-8 col-lg-9 mb-3 mb-md-0" [hidden]="!cartService.cart.length">
        <div class="card">
          <div class="card-body">
            <div class="row cart-box align-items-center" *ngFor="let item of cartService.cart$ | async ; let i = index"
              [key]=item.id>
              <div class="col-md-6 cart-item-info">
                <img [src]="item.image ? item.image : 'assets/img/products/default.jpg'" [alt]="item.product_name">
                <ul>
                  <li>{{item.product_name}}</li>
                  <li class="text-muted">MRP: {{ item.mrp_rate | currency:'INR' }}</li>
                  <li class="text-muted">Sales Rate: {{ item.sales_price | currency:'INR'}}</li>
                </ul>
              </div>
              <div class="col-md-3">
                <div class="input-group cart-item-count">
                  <div class="input-group-prepend">
                    <button class="btn btn-outline-primary" type="button" [disabled]="cartService.cartTotal<= 0" aria-label="Decrease Quantity"
                      (click)="ChangeQuantity(i, false, item.is_allow_decimal, false);">&#8722;</button>
                  </div>
                  <!-- OnlyNumber="{{(item.is_allow_decimal)?true:false}}" -->
                  <!-- <input type="textbox" OnlyNumber="{{(item.is_allow_decimal)?true:false}}" class="form-control" [(ngModel)]="item.qty"
                    (keyup)="ChangeQuantity(i, false, item.is_allow_decimal, true)" > -->

                    <input *ngIf="item.is_allow_decimal" type="number" msThreedigitdecimal class="form-control" [(ngModel)]="item.qty"
                    (keyup)="ChangeQuantity(i, false, item.is_allow_decimal, true)" min="1" (blur)="defaultQty(i, false, item.is_allow_decimal, true)" >

                    <input *ngIf="!item.is_allow_decimal" type="number" msNumberonlywithoutcontrol="numeric" class="form-control" [(ngModel)]="item.qty"
                    (keyup)="ChangeQuantity(i, false, item.is_allow_decimal, true)" min="1" (blur)="defaultQty(i ,false, item.is_allow_decimal, true)">

                  <div class="input-group-append">
                    <button class="btn btn-outline-primary" type="button" aria-label="Increase Quantity"
                      (click)="ChangeQuantity(i, true, item.is_allow_decimal, false);">&#43;</button>
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-6 col-lg-2">
                {{ item.sales_price * item.qty  | currency:'INR'}}
              </div>
              <div class="col-6 col-md-6 col-lg text-right cart-remove-wrp">
                <button type="button" class="btn btn-sm btn-outline-danger" aria-label="Remove Item" (click)="removeFromCart(item.id)">
                  <i class="fa fa-times"></i>
                </button>
              </div>
              <div class="col-md-12 text-center text-danger" *ngIf="!item.is_stock"> Out Of Stock</div>
            </div>

          </div>
          <div class="card-footer cart-item-action text-right d-none d-md-block">
            <a routerLink="/" class="btn btn-outline-primary mr-3"><i class="fa fa-chevron-left"></i>&nbsp;Continue
              Shopping</a>
            <button routerLink="{{(is_disable_checkout)?'/cart':'/checkout'}}" aria-label="Checkout Cart" [disabled]="is_disable_checkout || cartService.cartTotal<= 0"
              class="btn btn-primary">Checkout&nbsp;<i class="fa fa-chevron-right"></i></button>

          </div>
          <div *ngIf="is_disable_checkout" class="col-md-12 disabled-text">Cart contain out of stock items. Please clear
            it before doing checkout.</div>
        </div>
      </div>

      <div class="col-md-4 col-lg-3 cart-summry" [hidden]="!cartService.cart.length">
        <div class="card">
          <app-sidebar-widget [is_disable_checkout]=is_disable_checkout></app-sidebar-widget>
        </div>
      </div>

      <div class="row mx-auto" [hidden]="cartService.cart.length">
        <div style="text-align: center;">
          <h2>Your cart is empty!</h2>
          <small>Add items to it now.</small> <br><br>
          <a routerLink="/" class="btn btn-outline-primary"><i class="fa fa-chevron-left"></i>&nbsp;Shop Now</a>
        </div>
      </div>
    </div>
  </div>
</div>
