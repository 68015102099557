<header ngStickyNav stickyClass="header-fix">
    <div class="info-bar">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <ul>
                        <li><a href="tel:{{ storeMobile }}"><i class="fa fa-phone-alt"></i>&nbsp;{{ storeMobile }}</a></li>
                        <li><a href="mailto:{{ storeEmail }}"><i class="fa fa-envelope"></i>&nbsp;{{ storeEmail }}</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="search-bar">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-3">
                    <a routerLink='/'>
                        <!-- <img src="assets/img/we-care-logo.png" alt="We Care"> -->
                        <img src="assets/img/logo2.png" alt="We Care">

                    </a>
                </div>
                <div class="col-9">
                    <div class="row align-items-center position-relative">
                        <div class="col-9 col-md-10 col-lg-11" [formGroup]="form">
                            <div class="input-group">
                                <input type="text" aria-label="Search products" (keyup.enter)="search()" class="form-control {{(is_req && !form.valid)?'search-border':''}}" [formControl]="form.controls['search']" placeholder="Search products . . ." #q>


                                <div class="input-group-append">
                                    <button class="btn btn-primary" type="button" aria-label="Search" (click)="search()">
                                        <i class="fa fa-search"></i>
                                    </button>
                                </div>

                            </div>
                            <!-- <div class="text-left red-clr">{{(is_req && !form.valid)?'* Please enter any keyword':''}}</div> -->
                        </div>
                        
                        <button type="button" class="cart-btn-top btn-color" (click)="goToCart()">
                            <span class="badge badge-pill badge-danger">{{ cartService.cart.length }}</span>
                            <!-- <i class="fa fa-shopping-cart"></i> -->
                            <img src="assets/img/cart-edit.png" alt="">
                        </button>
                        <!-- Shopping cart drop down -->
                        <!-- <div class="shopping-cart" *ngIf="is_cart_is_open" #shoppingCart>
                            <div class="shopping-cart-header">
                                <i class="fa fa-shopping-cart cart-icon"></i><span class="badge">{{ cartService.cart.length }}</span>
                                <div class="shopping-cart-total">
                                    <span class="lighter-text">Total:</span>&nbsp;
                                    <span class="main-color-text">{{cartService.cartTotal | currency:'INR' }}</span>
                                </div>
                            </div>
                            <ul class="shopping-cart-items">
                                <li class="clearfix" *ngFor="let item of cartService.cart$ | async">
                                    <img [src]="item.image ? item.image : 'assets/img/products/default.jpg'" [alt]="item.product_name">
                                    <span class="item-name">{{ item.product_name }}</span>
                                    <span class="item-price">{{ item.sales_price | currency:'INR' }}</span>
                                    <span class="item-quantity">Quantity: {{item.qty}}</span>
                                </li>
                                <li [hidden]="cartService.cart.length">No Items Found</li>
                            </ul>
                            <div class="text-center">
                                <button type="button" (click)="toggleCart();deactivateOrderCart()" aria-label="View Cart" class="button btn-outline">Edit Cart</button>
                                <button type="button" aria-label="Checkout" (click)="toggleCart();deactivateOrderCheckout()" class="button btn btn-primary" [disabled]="!cartService.cart.length">Checkout</button>
                            </div>
                        </div> -->
                        <!-- End shopping cart drop down -->
                    </div>
                </div>
            </div>
        </div>
    </div>
<div class="menu-bar">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <button type="button" class="nav-prev" aria-label="Scroll Category to Left" (click)="scrollLeft()" ><i class="fa fa-chevron-left" ></i></button>
                <button type="button" class="nav-next" aria-label="Scroll Category to Right" (click)="scrollRight()" ><i class="fa fa-chevron-right" ></i></button>
                  <nav role="navigation" class="okayNav">
                      <ul #widgetsContent>
                          <li *ngFor="let category of categories">
                            <a [routerLink]="getCategoryRoute(category)" [routerLinkActive]="'is-active'" [state]="{category_id: category.id, category_name: category.name}">{{category.name}}</a>
                          </li>
                      </ul>
                  </nav>
            </div>
        </div>
    </div>
</div>
</header>
