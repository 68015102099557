// export const environment = {
//   production: true,
//   basePath: 'http://devvyapaar.demolocation.co.in/',
//   unitId: '239',
//   outletId: '81',
//   limit: "12",
//   store : {
//     name: 'Calicut Vyapar',
//     email: "calicut@vyapar.com",
//     mobile: "+91 8138904567"
//   }
// };
// export const environment = {
//   production: true,
//   basePath: 'http://localhost:3008/',
//   unitId: '194',
//   outletId: '8',
//   limit: "12",
//   store: {
//     name: 'Calicut Vyapar',
//     email: "calicut@vyapar.com",
//     mobile: "+91 8138904567",
//     message: "Please note that orders will be delivered on all days"
//   }
// };
export const environment = {
  production: true,
  basePath: 'https://devvyapaar.demolocation.co.in/',
  unitId: '239',
  outletId: '81',
  limit: "12",
  store: {
    name: 'Calicut BesPOS - Sesame Technologies',
    email: "calicut@vyapar.com",
    mobile: "+91 8138904567",
    message: "Please note that orders will be delivered on all days"
  }
};
