import { Component, OnInit, Inject, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl
} from "@angular/forms";
import { Router } from '@angular/router';
import { CartService } from '../service/cart.service';
import { CheckoutService } from '../service/checkout.service';
import { TitleService } from '../service/title.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Globals } from '../service/global';



@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})

export class CheckoutComponent implements OnInit {
  public form: FormGroup;
  @ViewChild('nameinputcontroller') private nameinputcontroller: ElementRef;
  public is_disable_checkout: boolean = false;
  public is_exist_customers: boolean = false;
  public is_picode_available: boolean = true;
  public is_deactivate_orders_cart: boolean = true;

  constructor(private fb: FormBuilder, private router: Router, public cartService: CartService, private title: TitleService, public dialog: MatDialog, private snackbar: MatSnackBar, public global: Globals, public checkout: CheckoutService) { }

  async ngOnInit() {
    this.title.setTitle('Checkout')

    //check cart items area valid or not
    //check cart is not empty
    let check_cart_items = (JSON.parse(localStorage.getItem('cart'))) ? JSON.parse(localStorage.getItem('cart')) : [];

    //check items are in stock
    let res = (this.cartService.cart).filter(x => x.is_stock === false);

    this.is_disable_checkout = (res.length > 0) ? true : false

    if (check_cart_items.length === 0 || this.is_disable_checkout) {
      this.router.navigate(['/cart']);
      return;
    }
    //End

   



    this.form = this.fb.group({
      name: [null, Validators.compose([Validators.required])],
      phone: [null, Validators.compose([Validators.required])],
      // email: [
      //   null,
      //   Validators.compose([Validators.required, Validators.email])
      // ],
      email: [
        null,
        Validators.compose([Validators.email])
      ],
      address: [null, Validators.compose([Validators.required])],
      location: [null, Validators.compose([Validators.required])],
      pincode: [null, Validators.compose([Validators.required])],
      address_2: [null],

    });

    //  Deactivated Product

    let orders_cart_response = await this.global.is_deactivate_orders_cart.toPromise();
    this.is_deactivate_orders_cart = orders_cart_response['is_deactivate_orders_cart']
    if (this.is_deactivate_orders_cart) {
      this.router.navigate(['/'])
    } else {
      this.getCustomInfoFilling();
    }

    // End





    let shipping_details = (JSON.parse(localStorage.getItem('shipping_details'))) ? JSON.parse(localStorage.getItem('shipping_details')) : ""
    if (shipping_details) {
      this.form.controls.name.setValue(shipping_details.name)
      this.form.controls.phone.setValue(shipping_details.phone)
      this.form.controls.email.setValue(shipping_details.email)
      this.form.controls.location.setValue(shipping_details.location)
      this.form.controls.address.setValue(shipping_details.address)
      this.form.controls.pincode.setValue(shipping_details.pincode)
      this.form.controls.address_2.setValue(shipping_details.address_2)

    }

    //For rightbar widget
    await this.cartService.updateCartTotal()
    //End rightbar widget

    if (this.form.controls.pincode.value) {
      this.checkout.checkPincodeAvailable(this.form.controls.pincode.value).subscribe((data: any) => {
        this.is_picode_available = data
      })
    }

  }

  shippingDetails() {
    //check contain items

    let check_cart_items = (JSON.parse(localStorage.getItem('cart'))) ? JSON.parse(localStorage.getItem('cart')) : [];

    //check items are in stock
    // let res = (this.cartService.cart).filter(x => x.is_stock !== true);
    let res = (this.cartService.cart).filter(x => x.is_stock === false);
    this.is_disable_checkout = (res.length > 0) ? true : false

    if (check_cart_items.length === 0 || this.is_disable_checkout) {
      this.router.navigate(['/cart']);
      return;
    }
    if (!this.form.valid) {
      return;
    } else {
      localStorage.setItem("shipping_details", JSON.stringify(this.form.value))
    }
  }

  getCustomInfoFilling() {

    window.scrollTo({
      top: 0,
    });

    let shipping_details_mob_check = (JSON.parse(localStorage.getItem('shipping_details'))) ? JSON.parse(localStorage.getItem('shipping_details')) : ""

    if (!shipping_details_mob_check) {
      let mobileNoPopupDialogRef = this.dialog.open(mobileNoPopupDialog, {
        disableClose: true
      });

      const sub = mobileNoPopupDialogRef.componentInstance.onAdd.subscribe((data: any) => {
        this.form.controls.phone.setValue(data.phone);



        this.cartService.getMobileNoDetails(data.phone).subscribe((res: any) => {

          if (res.length != 0) {
            this.is_exist_customers = true;
          } else {
            this.is_exist_customers = false;

          }

          this.form.controls.name.setValue(res.customer_name)
          this.form.controls.email.setValue(res.email_id)
          this.form.controls.location.setValue(res.location)
          this.form.controls.address.setValue(res.address)
          this.form.controls.pincode.setValue(res.pincode)
          this.form.controls.address_2.setValue(res.address_2)
          localStorage.setItem("shipping_details", JSON.stringify(this.form.value))

          if (res.pincode && res.pincode != null) {
            this.checkout.checkPincodeAvailable(res.pincode).subscribe((data: any) => {
              this.is_picode_available = data
            })
          }

          // console.log ("is_exist_customers----", this.is_exist_customers)
          let welcome_msg = this.is_exist_customers ? (`Welcome back ` + this.form.controls.name.value + `, please verify the address details and proceed `) : (`Welcome, Please enter your shipping details to proceed `);

          this.snackbar.open(welcome_msg, 'X', {
            duration: 60000,
            verticalPosition: 'top',
            horizontalPosition: 'right',
            panelClass: ['snackbar', 'blue-snackbar']
          });

        })






      });
      mobileNoPopupDialogRef.afterClosed().subscribe(result => {
        setTimeout(() => this.nameinputcontroller.nativeElement.focus(), 300)
      });

    }
  }

  reset() {
    this.form.reset();
    localStorage.removeItem('shipping_details');
    this.getCustomInfoFilling();
    this.is_picode_available = true
  }

  checkPincode(e) {
    if (e.target.value.length == 6) {
      let pincode = e.target.value
      this.checkout.checkPincodeAvailable(pincode).subscribe((data: any) => {
        this.is_picode_available = data
      })
    }
  }


}

@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'mobilenopopupdialog.component.html',
  styleUrls: ['./checkout.component.css']

})
export class mobileNoPopupDialog implements OnInit {

  public form: FormGroup;
  @Output() onAdd: EventEmitter<any> = new EventEmitter();


  constructor(
    public dialogRef: MatDialogRef<mobileNoPopupDialog>,
    private router: Router,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    dialogRef.disableClose = true;

  }

  onNoClick(): void {
    this.router.navigate(['/cart']);
    this.dialogRef.close();
  }
  ngOnInit() {

    this.form = this.fb.group({
      phone: [null, Validators.compose([Validators.required])],
    });


  }

  save(item): void {
    if(item.invalid){
      return
    }
    this.onAdd.emit(item.value);
    this.dialogRef.close();
  }

}
