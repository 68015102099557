import { Component, OnInit } from '@angular/core';
import { AppService } from '../service/app.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  public storeName;
  public storeEmail;
  public storeMobile;
  public storeMessage;
  constructor(private app: AppService) {
    this.storeName = app.storeName;
    this.storeEmail = app.storeEmail;
    this.storeMobile = app.storeMobile;
    this.storeMessage = app.storeMessage;
   }

  ngOnInit(): void {
  }

}
