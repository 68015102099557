
import { v4 as uuidv4 } from 'uuid';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Service } from './service'
import { environment } from '../../environments/environment';
import { Globals } from './global';
@Injectable({
  providedIn: 'root'
})
export class CartService extends Service {
  public readonly _cart = new BehaviorSubject<any>([]);
  readonly cart$ = this._cart.asObservable();
  cartTotal: number = 0;
  cartSubTotal: number = 0;
  cartTaxTotal: number = 0;
  roundOff: number = 0;
  public is_disable_checkout: boolean = true;
  public is_deactivate_orders_cart: boolean = false
  constructor(private http: HttpClient, private snackbar: MatSnackBar, private global: Globals) {
    super();
    let existingCartItems = (JSON.parse(localStorage.getItem('cart'))) ? JSON.parse(localStorage.getItem('cart')) : [];
    let reg_data
    (existingCartItems).filter((val, index) => {
      reg_data = {
        qty: val.qty,
        product_id: val.product_id,
        product_batch_id: val.product_batch_id
      }
      let checkStok = this.checkStock(reg_data)
        .subscribe((response) => {
          if (!response['isoutofstock']) {
            this.cart[index].is_stock = true
          } else {
            this.cart[index].is_stock = false
            this.is_disable_checkout = true
          }

        })
    })
    localStorage.setItem('cart', JSON.stringify(existingCartItems));

    this.updateCartTotal();
    if (!existingCartItems) {
      existingCartItems = [];
    }
    this._cart.next(existingCartItems);
    this.isDeactivateCart()
  }

  get cart() {
    return this._cart.getValue();
  }

  set cart(items) {
    this._cart.next(items);

    // let info: CartModelPublic = JSON.parse(localStorage.getItem('cart'));
    // let existingCartItems = JSON.parse(localStorage.getItem('cart'));

    // this._cart.next(existingCartItems);


  }
  async isDeactivateCart() {
    let orders_cart_response = await this.global.is_deactivate_orders_cart.toPromise();
    this.is_deactivate_orders_cart = orders_cart_response['is_deactivate_orders_cart']
  }

  addToCart(item) {
    this.isDeactivateCart()
    setTimeout(() => {
      if (this.is_deactivate_orders_cart) {
        this.productDeactivate()
      } else {
        let isExist = this.cart.filter(cart => cart.product_id === item.product_id);
        // const config = new MatSnackBarConfig();
        // config.duration = 10000;
        // config.verticalPosition = "top";
        // config.horizontalPosition = "right";
        if (isExist.length > 0) {
          //config.panelClass = ['snackbar'];
          this.snackbar.open(`${item.product_name} already present in the cart`, 'X', {
            duration: 60000,
            verticalPosition: 'top',
            horizontalPosition: 'right',
            panelClass: ['snackbar']
          });
          return;
        }

        //config.panelClass = ['snackbar', 'blue-snackbar'];

        this.snackbar.open(`${item.product_name} added to cart`, 'X', {
          duration: 60000,
          verticalPosition: 'top',
          horizontalPosition: 'right',
          panelClass: ['snackbar', 'blue-snackbar']
        });


        this.cart = [
          ...this.cart,
          { ...item, id: uuidv4(), qty: 1 }
        ];
        localStorage.setItem('cart', JSON.stringify(this.cart));


        this.updateCartTotal();
      }
    }, 100);




  }

  removeCart(id) {
    this.cart = this.cart.filter(cart => cart.id !== id);
    localStorage.setItem('cart', JSON.stringify(this.cart));
    this.updateCartTotal();

  }


  checkStock(data) {
    let headers: any = {
      'unitid': environment.unitId,
      'outletid': environment.outletId,
    }
    return this.http.post(`${this.pasePath}shoppingcart/checkqtystock`, data, {
      headers
    });
  }

  getSettings() {
    return this.http.get(`${this.pasePath}shoppingcart/settings`, {
      headers: {
        'unitid': environment.unitId,
      }
    });
  }

  getMobileNoDetails(mobile) {
    return this.http.get(`${this.pasePath}shoppingcart/mobilenosearch/${mobile}`, {
      headers: {
        'unitid': environment.unitId,
      }
    });
  }

  async RoundValueDetails(Value, RoundType) {
    var RoundResult;
    var strRTO;
    Value = Number(Value)

    RoundType = RoundType.trim();
    RoundType = RoundType.toUpperCase();

    switch (RoundType.substr(0, 1)) {
      case "L":
        strRTO = "L";
        RoundType = RoundType.replace("L", "");
        break;
      case "U":
        strRTO = "U";
        RoundType = RoundType.replace("U", "");
        break;
      case "N":
        strRTO = "N";
        RoundType = RoundType.replace("N", "");
        break;
      default:
        strRTO = "N";
    }

    RoundType = Number(RoundType)

    if (RoundType > 0) {
      switch (strRTO) {

        case "N":
          RoundResult = Math.trunc(((Value + (RoundType / 2)) * (1 / RoundType))) / (1 / RoundType)
          break;

        case "L":
          RoundResult = Math.trunc(Value * (1 / RoundType)) / (1 / RoundType)
          break;

        case "U":
          RoundResult = Math.trunc(Value * (1 / RoundType)) / (1 / RoundType)
          var absResult = (Value > RoundResult ? 1 : 0)
          RoundResult = RoundResult + (absResult * RoundType)
          break;
      }
    } else {
      RoundResult = Value;
    }
    return RoundResult;
  }

  async updateCartTotal() {
    //the code to update the total property of the cart
    let settings = await this.getSettings().toPromise();
    let round_key = settings[0].round_key
    round_key = (round_key == null) ? '0' : round_key


    let total = this.cart.reduce((total, curr) =>
      parseFloat(total) + (parseFloat(curr.qty) * parseFloat(curr.sales_price)), 0).toFixed(2);


    let cgst_amount = this.cart
      .reduce(
        (cgst_amount, curr) => parseFloat(cgst_amount) + (((parseFloat(curr.qty) * parseFloat(curr.sales_price)) * parseFloat(curr.sales_sgst_percentage)) / (100 + parseFloat(curr.sales_cgst_percentage) + parseFloat(curr.sales_sgst_percentage) + parseFloat(curr.sales_additional_tax_percentage) + parseFloat(curr.sales_cess_percentage))),
        0
      )
      .toFixed(2);


    let sgst_amount = this.cart
      .reduce(
        (sgst_amount, curr) => parseFloat(sgst_amount) + (((parseFloat(curr.qty) * parseFloat(curr.sales_price)) * parseFloat(curr.sales_sgst_percentage)) / (100 + parseFloat(curr.sales_cgst_percentage) + parseFloat(curr.sales_sgst_percentage) + parseFloat(curr.sales_additional_tax_percentage) + parseFloat(curr.sales_cess_percentage))),
        0
      )
      .toFixed(2);
    let cess_amount = this.cart
      .reduce(
        (cess_amount, curr) => parseFloat(cess_amount) + (((parseFloat(curr.qty) * parseFloat(curr.sales_price)) * parseFloat(curr.sales_cess_percentage)) / (100 + parseFloat(curr.sales_cgst_percentage) + parseFloat(curr.sales_sgst_percentage) + parseFloat(curr.sales_additional_tax_percentage) + parseFloat(curr.sales_cess_percentage))),
        0
      )
      .toFixed(2);
    let additional_tax_amount = this.cart
      .reduce(
        (additional_tax_amount, curr) => parseFloat(additional_tax_amount) + (((parseFloat(curr.qty) * parseFloat(curr.sales_price)) * parseFloat(curr.sales_additional_tax_percentage)) / (100 + parseFloat(curr.sales_cgst_percentage) + parseFloat(curr.sales_sgst_percentage) + parseFloat(curr.sales_additional_tax_percentage) + parseFloat(curr.sales_cess_percentage))),
        0
      )
      .toFixed(2);

    let subtotal = (parseFloat(total) - parseFloat(cgst_amount) - parseFloat(sgst_amount) - parseFloat(cess_amount) - parseFloat(additional_tax_amount)).toFixed(2);
    //   // let rate = (parseFloat(amount) / parseFloat(qty)).toFixed(2);
    let total_tax_amount = (parseFloat(cgst_amount) + parseFloat(sgst_amount) + parseFloat(cess_amount) + parseFloat(additional_tax_amount)).toFixed(2);
    // let total_tax_amount = (parseFloat(cgst_amount) + parseFloat(sgst_amount)).toFixed(2);
    this.cartSubTotal = parseFloat(subtotal);
    this.cartTaxTotal = parseFloat(total_tax_amount);
    let round_results = await this.RoundValueDetails(total, round_key);

    let round_off = (parseFloat(round_results) - parseFloat(total)).toFixed(2)
    total = round_results.toFixed(2);

    this.cartTotal = total;
    this.roundOff = parseFloat(round_off)
    // console.log ("this.cartSubTotal ", this.cartSubTotal ,  this.cartTaxTotal,   this.cartTotal)

    if (this.cart.length === 0) {
      localStorage.clear();
    }
  }

  productDeactivate() {
    this.snackbar.open(`Sorry. Items can not be purchased at this moment since we are working on completing current orders. Please check back after some time.`, 'X', {
      duration: 60000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
      panelClass: ['snackbar']
    });
  }

}
