import { Component, OnInit } from '@angular/core';
import { CartService } from '../service/cart.service';
import { CategoryService } from '../service/category.service'
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TitleService } from '../service/title.service';
import { Globals } from "../service/global"
import { Router } from '@angular/router';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {
  public is_disable_checkout: boolean = false;
  public is_nil_stock_orders_cart: boolean = false;
  public is_deactivate_orders_cart:boolean=false
  constructor(private global: Globals,
    public cartService: CartService,
    public categoryService: CategoryService,
    private snackbar: MatSnackBar,
    private title: TitleService,
    public router:Router) { }

  async ngOnInit() {
    let response = await this.global.is_nil_stock_orders_cart.toPromise();
    this.is_nil_stock_orders_cart = response['is_nil_stock_orders_cart']

    let orders_cart_response = await this.global.is_deactivate_orders_cart.toPromise();
    this.is_deactivate_orders_cart = orders_cart_response['is_deactivate_orders_cart']
    if(this.is_deactivate_orders_cart){
      this.router.navigate(['/'])
    }

    this.title.setTitle('Cart')
    const config = new MatSnackBarConfig();
    config.panelClass = ['snackbar'];
    config.duration = 4000;
    (this.cartService.cart).filter(async (val, index) => {
      let data = val;
      data.qty = Number(data.qty)
      let reg_data = {
        qty: data.qty,
        product_id: data.product_id,
        product_batch_id: data.product_batch_id
      }
      if (!this.is_nil_stock_orders_cart) {
        let checkStok = await this.cartService.checkStock(reg_data).toPromise();
        if (!checkStok['isoutofstock']) {
          this.cartService.cart[index].is_stock = true

          if (this.cartService.cart[index].qty == 0) {
            this.is_disable_checkout = true
            this.cartService.cart[index].is_stock = true

          }

        } else {
          this.cartService.cart[index].qty = data.qty
          this.cartService.cart[index].is_stock = false
          this.is_disable_checkout = true
        }
      } else {
        this.cartService.cart[index].is_stock = true

        if (this.cartService.cart[index].qty == 0) {
          this.is_disable_checkout = true
          this.cartService.cart[index].is_stock = true

        }
      }
    })
    localStorage.setItem('cart', JSON.stringify(this.cartService.cart));
    await this.cartService.updateCartTotal()

  }

  removeFromCart(id) {
    this.cartService.removeCart(id);
    (this.cartService.cart).filter(async (val, index) => {
      let data = val;
      data.qty = Number(data.qty)
      let reg_data = {
        qty: data.qty,
        product_id: data.product_id,
        product_batch_id: data.product_batch_id
      }
      if (!this.is_nil_stock_orders_cart) {
        let checkStok = await this.cartService.checkStock(reg_data).toPromise();
        if (!checkStok['isoutofstock']) {
          this.cartService.cart[index].is_stock = true
          this.is_disable_checkout = false

          if (this.cartService.cart[index].qty == 0) {
            this.is_disable_checkout = true
            this.cartService.cart[index].is_stock = true

          }

        } else {
          this.cartService.cart[index].qty = data.qty
          this.cartService.cart[index].is_stock = false
          this.is_disable_checkout = true
        }
      } else {
        this.cartService.cart[index].is_stock = true
        this.is_disable_checkout = false

        if (this.cartService.cart[index].qty == 0) {
          this.is_disable_checkout = true
          this.cartService.cart[index].is_stock = true

        }
      }
    })

  }

  defaultQty(id: any, increaseQuantity: Boolean, is_numeric: Boolean, is_typable: Boolean) {
    let qty = this.cartService.cart[id].qty
    if (qty < 1) {
      this.cartService.cart[id].qty = 1;
      this.UpdateCartData(id, this.cartService.cart[id].qty, is_numeric, is_typable).then(async val1 => {

        let res = (this.cartService.cart).filter(x => x.is_stock !== true);
        this.is_disable_checkout = (res.length > 0) ? true : false

        localStorage.setItem('cart', JSON.stringify(this.cartService.cart));
        await this.cartService.updateCartTotal()
      });
      // return;


    }

  }

  async ChangeQuantity(id: any, increaseQuantity: Boolean, is_numeric: Boolean, is_typable: Boolean) {
    let qty = this.cartService.cart[id].qty
    // if(qty < 1) {
    //   this.cartService.cart[id].qty = 1
    //   return;
    // }
    this.UpdateCartData(id, increaseQuantity, is_numeric, is_typable).then(async val1 => {

      let res = (this.cartService.cart).filter(x => x.is_stock !== true);
      this.is_disable_checkout = (res.length > 0) ? true : false

      localStorage.setItem('cart', JSON.stringify(this.cartService.cart));
      if (qty < 1) {
        return;
      }
      await this.cartService.updateCartTotal()
    });
  }

  async UpdateCartData(index, increase: Boolean, is_numeric: Boolean, is_typable: Boolean) {
    let data = this.cartService.cart[index];
    data.qty = this.cartService.cart[index].qty
    // if(data.qty < 1) {
    //   this.cartService.cart[index].qty = 1
    //   return;
    // }
    data.current_stock = Number(data.current_stock)
    let reg_data;
    if (is_typable) {
      reg_data = {
        qty: data.qty,
        product_id: data.product_id,
        product_batch_id: data.product_batch_id
      }
    } else {
      if (increase) {
        data.qty++;
        reg_data = {
          qty: data.qty,
          product_id: data.product_id,
          product_batch_id: data.product_batch_id
        }
      } else {
        // @ts-ignore
        data.qty--;

        // @ts-ignore
        if (data.qty < 1) {
          data.qty = 1

          reg_data = {
            qty: data.qty,
            product_id: data.product_id,
            product_batch_id: data.product_batch_id
          }

        } else {
          reg_data = {
            qty: data.qty,
            product_id: data.product_id,
            product_batch_id: data.product_batch_id
          }
        }
      }
    }
    if (!reg_data)
      return;
    if (!this.is_nil_stock_orders_cart) {
      let checkStok = await this.cartService.checkStock(reg_data).toPromise();
      if (!checkStok['isoutofstock']) {
        this.cartService.cart[index].qty = data.qty;
        this.cartService.cart[index].is_stock = true

        if (this.cartService.cart[index].qty == 0) {
          this.is_disable_checkout = true
          this.cartService.cart[index].is_stock = true

        }


      } else {
        //data.qty--;
        this.cartService.cart[index].qty = data.qty;
        this.cartService.cart[index].is_stock = false
      }
    } else {
      this.cartService.cart[index].qty = data.qty;
      this.cartService.cart[index].is_stock = true

      if (this.cartService.cart[index].qty == 0) {
        this.is_disable_checkout = true
        this.cartService.cart[index].is_stock = true

      }
    }

    localStorage.setItem('cart', JSON.stringify(this.cartService.cart));
    if (this.cartService.cart[index].qty < 1) {
      return;
    }
    this.cartService.updateCartTotal();

  }
}
