<div class="page-ttl-wrap">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1>Privacy Policy</h1>
                <ul>
                  <li><a routerLink="/">Home</a></li>
                  <li>Privacy Policy</li>
                </ul>
            </div>
        </div>
    </div>
</div>
<div class="page-cntnt-wrap">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <p>This website provides a registration process for availing all services offered through it and may now or in the future collect the personally identifiable information  of every visitor/customer with the intention to serve them in a better way. </p>

                <p>Personally identifiable information includes first and last name , Email Address, mobile phone numbers, and contact details including location , identification marks, Post and Pin code,  Personal  Details like  age, gender, occupation, location, education, address etc.  </p>
                
                <p>By completing the registration and placing the order, the customer agrees to receive promotional and transactional communications via SMS, mobile notification, phone Calls, New letters, Face Book alerts, WhatsApp messages and digital media network. The customer can opt out from this service by contacting the customer support number provided at the website  www.wecareretail.com.   </p>
                
                <p>We will never sell or provide your personal information to any third party companies or outside agencies for commercial purpose directly or indirectly. However, the site may contain links to other websites that we do not manage directly such as google analytics used to understand and optimize user behavior. WECARE SWAYAM SAHAYA SANGAM is not be responsible for the policies adopted by such external websites.</p>
                
                <h5>Text Messaging </h5>
                
                <p>By ordering through www.wecareretail.com site, you agree that WECARE SWAYAM SAHAYA SANGAM may send you messages as part of the normal business operations and personalized services to any customer. You automatically acknowledge that any such messages opened from your side is read and understood by you.</p>
                
                
                <h5>Use of data reeived through Reviews, feedback, submissions</h5>
                <p>All reviews, comments, feedback, Post cards, suggestions, ideas and other submissions etc. shall be kept disclosed but will be evaluated by WECARE SWAYAM SAHAYA SANGAM  in order to improve the service quality and for training purpose of our internal team.</p>
                
                
                <h5>Indemnity</h5>
                <p>You agree to defend indemnify and hold harmless WECARE SWAYAM SAHAYA SANGAM, its employees, directors, officers, agents, and their successors and assign from and against any and all claims, liabilities, damages, losses, cost and expenses, including attorney’s fees, caused by or arising out of claim based upon your actions or inactions. Which may result in any loss or liability to WECARE SWAYAM SAHAYA SANGAM or any third party including but not limited to breach of any warranties representatives or undertaking or in relation to the non-fulfillment  of any of your obligation under this user agreement or arising out of the  your violation at any applicable laws, statutory dues and taxes etc., This clause shall survive the expiry of or termination of the user agreement.</p>
            </div>
        </div>
    </div>
</div>